import React from "react";
import '../SeccionPDF/SeccionPDF.css'
import { MdDownload } from "react-icons/md";

// Imágenes
import CatalogoNil2025 from '../../assets/img/2025/CATALOGO_NILFISK_2025.jpg'
import CatalogoVip2025 from '../../assets/img/2025/CATALOGO_VIPER_2025.jpg'
import LimpiezaAutonoma from '../../assets/img/2025/LIMPIEZA_AUTONOMA_LIBERTY_SC50_Y_SC60.jpg'
import AspiracionIndustrial from '../../assets/img/2025/INDUSTRIA_MINERA.jpg'
import IndustriaAlimenticiaIMG from '../../assets/img/2025/INDUSTRIA_ALIMENTARIA.jpg'

// PDFs
import CatalogoNilfisk2025 from '../../assets/PDF/Nilfisk_Chile_Catalogo_2025.pdf';
import CatalogoVIPER2025 from '../../assets/PDF/NILFISK_Chile_Catalogo_VIPER_2025.pdf';
import LimpiezaAutónomaLiberty_SC50ySC60_2025 from  '../../assets/PDF/NILFISK_Chile_Limpieza_Autonoma_Liberty_SC50_y_SC60_2025.pdf';
import AspiraciónIndustrialEnMineríaCamionesDeAltoTonelaje from '../../assets/PDF/NILFISK_Chile_Aspiracion_Industrial_en_Mineria_camiones_de_alto_tonelaje.pdf';
import IndustriaAlimenticia from '../../assets/PDF/NILFISK_Chile_Industria_Alimenticia.pdf';

function PDF() {

  const downloadFile = (fileUrl, fileName) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };

  const handleDownload = (pdf, fileName) => {
    downloadFile(pdf, fileName);
  };

  return (
    <section className="container">
      <div className="row">
        <div className="col-12 py-5">
          <h4 className="RobotoMedio">
            Las máquinas Nilfisk ofrecen soluciones de limpieza de alto rendimiento, diseñadas para maximizar la eficiencia y garantizar resultados excepcionales en cualquier entorno.
          </h4>
        </div>

        <div className="col-12 col-lg py-4">
          <img className="boxImg" src={CatalogoNil2025} alt="Catálogo Nilfisk 2025" />
          <p className="mt-3 RobotoLigth">Catálogo Nilfisk <br />2025</p>
          <button className="btn btn-link text-white btn-linea" onClick={() => handleDownload(CatalogoNilfisk2025, "Catálogo_Nilfisk_2025.pdf")}>
            <MdDownload />PDF
          </button>
        </div>

        <div className="col-12 col-lg py-4">
          <img className="boxImg" src={CatalogoVip2025} alt="Catálogo VIPER 2025" />
          <p className="mt-3 RobotoLigth">Catálogo VIPER <br />2025</p>
          <button className="btn btn-link text-white btn-linea" onClick={() => handleDownload(CatalogoVIPER2025, "Catálogo_VIPER_2025.pdf")}>
            <MdDownload />PDF
          </button>
        </div>

        <div className="col-12 col-lg py-4">
          <img className="boxImg" src={LimpiezaAutonoma} alt="Limpieza Autónoma Liberty SC50 y SC60 2025" />
          <p className="mt-3 RobotoLigth">Limpieza Autónoma Liberty SC50 y SC60 2025</p>
          <button className="btn btn-link text-white btn-linea" onClick={() => handleDownload(LimpiezaAutónomaLiberty_SC50ySC60_2025, "Limpieza_Autonoma_Liberty_SC50_SC60_2025.pdf")}>
            <MdDownload />PDF
          </button>
        </div>

        <div className="col-12 col-lg py-4">
          <img className="boxImg" src={AspiracionIndustrial} alt="Industria Minera" />
          <p className="mt-3 RobotoLigth">Industria <br />Minera</p>
          <button className="btn btn-link text-white btn-linea" onClick={() => handleDownload(AspiraciónIndustrialEnMineríaCamionesDeAltoTonelaje, "Industria_Minera_Aspiracion_Industrial.pdf")}>
            <MdDownload />PDF
          </button>
        </div>

        <div className="col-12 col-lg py-4">
          <img className="boxImg" src={IndustriaAlimenticiaIMG} alt="Industria Alimentaria" />
          <p className="mt-3 RobotoLigth">Industria <br />Alimentaria</p>
          <button className="btn btn-link text-white btn-linea" onClick={() => handleDownload(IndustriaAlimenticia, "Industria_Alimentaria.pdf")}>
            <MdDownload />PDF
          </button>
        </div>
      </div>
    </section>
  );
}

export default PDF;