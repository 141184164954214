import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import './NavBar.css'



function NavBar() {
  return (
    <Navbar className='nav py-4' expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="#"><img className='logoV2' src="https://nilfiskchile.cl/assets/img/menu/logo-v2.svg" alt='Nilfisk logo' /></Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default NavBar;